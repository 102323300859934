@charset "UTF-8";
/*-------------------------------------------------
title : Basic style sheet
Author : Gahye Park
Create date :2023. 05. 10
-------------------------------------------------*/
/*-------------------------------------------------
title : mixin style sheet 
Author : Gahye Park
Create date :2021. 12. 06
-------------------------------------------------*/
/*-------------------------------------------------
title : variables style sheet
Author : Gahye Park
Create date :2021. 12. 06
-------------------------------------------------*/
@import "assets/fonts/pretendard/fonts.css";
@import "assets/fonts/NotoSansKR/fonts.css";
@import "assets/fonts/line-awesome/line-awesome.min.css";
@import "assets/fonts/xeicon/xeicon.min.css";
/*************************************
    # dashboard 공통 변수 
************************************/
/* font family */
/* font color */
/* responsive */
/* reset.css */
html {
  font-size: 20px;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -o-text-size-adjust: none;
  overscroll-behavior: contain;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Pretendard", "NotoSans", "돋움", "Dotum", "굴림", "Gulim", AppleGothic, UnDotum, Arial, Tahoma, Verdana, sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.6;
  color: #333;
}

ul, ol, li, dl, dt, dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  margin: 0;
  padding: 0;
  line-height: 0;
  vertical-align: middle;
  font-size: 0;
  border: 0;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

caption, legend {
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

select, input {
  vertical-align: middle;
}

address, em, i {
  font-style: normal;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  color: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
}

hr {
  display: block;
  margin: 0;
  padding: 0;
  height: 0;
  border: none;
}

figure, figcaption {
  margin: 0;
  padding: 0;
}

form, fieldset {
  margin: 0;
  padding: 0;
  line-height: 1;
  border: none;
}

button, [type=button], [type=reset], [type=submit] {
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
} /* iOS의 기본 스타일은 제거되고, css에서 사용자가 정의한 스타일로 반영 */
header, footer, article, section, aside, nav, main {
  display: block;
}

small {
  font-size: 0.65rem;
} /* Add the correct font size in all browsers. */
select,
input[type=text] {
  background: transparent;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand,
input[type=text]::-ms-expand {
  display: none;
}

/* Prevent `sub` and `sup` elements from affecting the line height in all browsers. */
sub, sup {
  position: relative;
  line-height: 0;
  vertical-align: baseline;
  font-size: 0.6rem;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup, main,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

b, strong {
  font: inherit;
}

/* 숨김 처리 */
.hidden {
  display: none;
}

.hid {
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* 정렬 */
.al {
  text-align: left !important;
}

.ac {
  text-align: center !important;
}

.ar {
  text-align: right !important;
}

.fl {
  float: left !important;
}

.fr {
  float: right !important;
}

.vm {
  vertical-align: middle !important;
}

.tl {
  text-align: left;
}

.tr {
  text-align: right;
}

.tc {
  text-align: center;
}

/* 플롯시 컨텐츠 공백 해결 */
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  clear: both;
  display: block;
  content: "";
}

/* Skip 메뉴 */
#skipArea {
  position: absolute;
  top: -1000px;
  left: 0;
  width: 100%;
  height: 0px;
  line-height: 0px;
  font-size: 0px;
  z-index: 1000;
}
#skipArea a {
  display: block;
  width: 100%;
  line-height: 0px;
  text-align: center;
  font-size: 0px;
}
#skipArea a:focus, #skipArea a:hover {
  position: absolute;
  top: 1000px;
  left: 0px;
  display: block;
  height: 30px;
  line-height: 30px;
  font-size: 0.8rem;
  font-weight: bold;
  color: #fff;
  background: #20262c;
}

/* 슬라이드 공용 */
.slick-slider {
  transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-slide {
  display: none;
  height: 100%;
  min-height: 1px;
  vertical-align: middle;
}

.slick-list {
  overflow: hidden;
  height: 100%;
}

.slick-list.dragging {
  cursor: pointer;
}

.slick-initialized .slick-slide {
  display: inline-block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-current {
  display: block;
  opacity: 1;
}

.slick-track {
  height: 100%;
}

/* 베트남 대시보드 소스 */
.e-dis-none {
  display: none;
}

.e-graph-with-auto {
  width: auto !important;
}

/* 부트스트랩 */
/* col padding defalut */
.col-h-wrap {
  width: 100%;
  height: 100%;
  margin: -0.3rem 0;
}

.col-minH-wrap {
  max-height: calc(100vh - 4.65rem);
}
.col-minH-wrap.col-minH-fix {
  height: 850px;
}
.col-minH-wrap.col-minH-fix * ::-webkit-scrollbar {
  height: 0.15rem;
}
.col-minH-wrap.col-minH-fix * ::-webkit-scrollbar-thumb {
  border: 0;
  border-radius: 0.5rem;
  background: #d8d8d8;
  background-clip: padding-box;
}
.col-minH-wrap.col-minH-fix * ::-webkit-scrollbar-track {
  border-radius: 0.5rem;
  background: #eee;
}

ul[class^=tab-st] + .col-wrap:first-of-type [class^=col-md] {
  padding-top: 0.3rem;
}

[class^=srh-filter] + .col-wrap {
  margin-top: 0.3rem;
}

.col-wrap {
  display: flex;
  margin: 0 -0.3rem;
  /* 그리드 최소높이값 */
}
.col-wrap:after {
  display: block;
  clear: both;
  content: "";
}
.col-wrap:first-of-type [class^=col-md] {
  padding-top: 0;
}
.col-wrap .cont {
  height: 100%;
  padding: 0.75rem 1rem;
  background: #fff;
}
.col-wrap .cont.cont-x-scrl {
  overflow: hidden;
  overflow-x: auto;
}
.col-wrap .cont2 {
  padding: 0.75rem 1rem;
  background: #fbfbfb;
}
.col-wrap .cont-pd-none {
  padding: 0;
  background: #fff;
}
.col-wrap .contFlex {
  display: flex;
  flex-wrap: wrap;
}
.col-wrap .min-h100-type1 {
  min-height: calc(100vh - 10.7rem);
}
.col-wrap .min-h100-type2 {
  min-height: calc(100vh - 7.75rem);
}

/* col padding 1.5rem */
.col-wrap2 {
  display: flex;
  margin: 0 -1.5rem;
}
.col-wrap2:after {
  display: block;
  clear: both;
  content: "";
}
.col-wrap2 .cont {
  height: 100%;
  background: #fff;
}
.col-wrap2 .cont2 {
  background: #fbfbfb;
}
.col-wrap2 .cont-pd-none {
  padding: 0;
  background: #fff;
}
.col-wrap2 .col-xs-1, .col-wrap2 .col-sm-1, .col-wrap2 .col-md-1, .col-wrap2 .col-lg-1, .col-wrap2 .col-xs-2, .col-wrap2 .col-sm-2, .col-wrap2 .col-md-2, .col-wrap2 .col-md-2_5, .col-wrap2 .col-lg-2, .col-wrap2 .col-xs-3, .col-wrap2 .col-sm-3, .col-wrap2 .col-md-3, .col-wrap2 .col-lg-3, .col-wrap2 .col-xs-4, .col-wrap2 .col-sm-4, .col-wrap2 .col-md-4, .col-wrap2 .col-lg-4, .col-wrap2 .col-xs-5, .col-wrap2 .col-sm-5, .col-wrap2 .col-md-5, .col-wrap2 .col-lg-5, .col-wrap2 .col-xs-6, .col-wrap2 .col-sm-6, .col-wrap2 .col-md-6, .col-wrap2 .col-lg-6, .col-wrap2 .col- .col-wrap2 .col-md-6_5xs-7, .col-wrap2 .col-sm-7, .col-wrap2 .col-md-7, .col-wrap2 .col-lg-7, .col-wrap2 .col-xs-8, .col-wrap2 .col-sm-8, .col-wrap2 .col-md-8, .col-wrap2 .col-lg-8, .col-wrap2 .col-xs-9, .col-wrap2 .col-sm-9, .col-wrap2 .col-md-9, .col-wrap2 .col-lg-9, .col-wrap2 .col-xs-10, .col-wrap2 .col-sm-10, .col-wrap2 .col-md-10, .col-wrap2 .col-lg-10, .col-wrap2 .col-xs-11, .col-wrap2 .col-sm-11, .col-wrap2 .col-md-11, .col-wrap2 .col-lg-11, .col-wrap2 .col-xs-12, .col-wrap2 .col-sm-12, .col-wrap2 .col-md-12, .col-wrap2 .col-lg-12, .col-wrap2 .col-h-1, .col-wrap2 .col-h-2, .col-wrap2 .col-h-3, .col-wrap2 .col-h-4, .col-wrap2 .col-h-5, .col-wrap2 .col-h-6x, .col-wrap2 .col-h-7, .col-wrap2 .col-h-8, .col-wrap2 .col-h-9, .col-wrap2 .col-h-10, .col-wrap2 .col-h-11, .col-wrap2 .col-h-12 {
  padding: 1.5rem;
}

/* col padding top none */
.col-wrap3 {
  display: flex;
  margin: 0 -0.3rem;
}
.col-wrap3:after {
  display: block;
  clear: both;
  content: "";
}
.col-wrap3 .cont {
  height: 100%;
  height: 100%;
  background: #fff;
}
.col-wrap3 .cont2 {
  padding: 0.75rem 1rem;
  background: #fbfbfb;
}
.col-wrap3 .cont-pd-none {
  padding: 0;
  background: #fff;
}
.col-wrap3 .col-xs-1, .col-wrap3 .col-sm-1, .col-wrap3 .col-md-1, .col-wrap3 .col-lg-1, .col-wrap3 .col-xs-2, .col-wrap3 .col-sm-2, .col-wrap3 .col-md-2, .col-wrap3 .col-md-2_5, .col-wrap3 .col-lg-2, .col-wrap3 .col-xs-3, .col-wrap3 .col-sm-3, .col-wrap3 .col-md-3, .col-wrap3 .col-lg-3, .col-wrap3 .col-xs-4, .col-wrap3 .col-sm-4, .col-wrap3 .col-md-4, .col-wrap3 .col-lg-4, .col-wrap3 .col-md-4-5, .col-wrap3 .col-xs-5, .col-wrap3 .col-sm-5, .col-wrap3 .col-md-5, .col-wrap3 .col-lg-5, .col-wrap3 .col-xs-6, .col-wrap3 .col-sm-6, .col-wrap3 .col-md-6, .col-wrap3 .col-md-6_5 .col-wrap3 .col-lg-6, .col-wrap3 .col-xs-7, .col-wrap3 .col-sm-7, .col-wrap3 .col-md-7, .col-wrap3 .col-lg-7, .col-wrap3 .col-xs-8, .col-wrap3 .col-sm-8, .col-wrap3 .col-md-8, .col-wrap3 .col-lg-8, .col-wrap3 .col-xs-9, .col-wrap3 .col-sm-9, .col-wrap3 .col-md-9, .col-wrap3 .col-lg-9, .col-wrap3 .col-xs-10, .col-wrap3 .col-sm-10, .col-wrap3 .col-md-10, .col-wrap3 .col-lg-10, .col-wrap3 .col-xs-11, .col-wrap3 .col-sm-11, .col-wrap3 .col-md-11, .col-wrap3 .col-lg-11, .col-wrap3 .col-xs-12, .col-wrap3 .col-sm-12, .col-wrap3 .col-md-12, .col-wrap3 .col-lg-12, .col-wrap3 .col-h-1, .col-wrap3 .col-h-2, .col-wrap3 .col-h-3, .col-wrap3 .col-h-4, .col-wrap3 .col-h-5, .col-wrap3 .col-h-6x, .col-wrap3 .col-h-7, .col-wrap3 .col-h-8, .col-wrap3 .col-h-9, .col-wrap3 .col-h-10, .col-wrap3 .col-h-11, .col-wrap3 .col-h-12 {
  padding: 0 0.3rem;
}

[class^=col-h] {
  width: 100%;
  float: none;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-md-2_5, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-md-4-5, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-md-6_5 .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12, .col-h-1, .col-h-2, .col-h-3, .col-h-4, .col-h-5, .col-h-6x, .col-h-7, .col-h-8, .col-h-9, .col-h-10, .col-h-11, .col-h-12 {
  position: relative;
  min-height: 1px;
  padding: 0.3rem;
}
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}

/* admin */
.admin .col-wrap .cont {
  background: linear-gradient(136deg, rgb(54, 57, 62) 0%, rgb(44, 48, 57) 100%);
}
.admin .col-wrap.col-minH-wrap.col-minH-fix * ::-webkit-scrollbar-thumb {
  background: #b5bfdb;
}
.admin .col-wrap.col-minH-wrap.col-minH-fix * ::-webkit-scrollbar-track {
  background: #434854;
}

@media (min-width: 600px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6_5 {
    width: 54%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-4-5 {
    width: 37.5%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2_5 {
    width: 23%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-h-12 {
    height: 100%;
  }
  .col-h-11 {
    height: 91.66666667%;
  }
  .col-h-10 {
    height: 83.33333333%;
  }
  .col-h-9 {
    height: 75%;
  }
  .col-h-8 {
    height: 66.66666667%;
  }
  .col-h-7 {
    height: 58.33333333%;
  }
  .col-h-6 {
    height: 50%;
  }
  .col-h-5 {
    height: 41.66666667%;
  }
  .col-h-4 {
    height: 33.33333333%;
  }
  .col-h-3 {
    height: 25%;
  }
  .col-h-2 {
    height: 16.66666667%;
  }
  .col-h-1 {
    height: 8.33333333%;
  }
}
@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
}
/* responsive */
@media screen and (max-width: 1200px) {
  html {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}