/*!
 * Fancytree "Win8" skin.
 *
 * DON'T EDIT THE CSS FILE DIRECTLY, since it is automatically generated from
 * the LESS templates.
 */
/*******************************************************************************
 * Common Styles for Fancytree Skins.
 *
 * This section is automatically generated from the `skin-common.less` template.
 ******************************************************************************/
/*------------------------------------------------------------------------------
 * Helpers
 *----------------------------------------------------------------------------*/
 .ui-helper-hidden {
  display: none;
}
/*------------------------------------------------------------------------------
 * Container and UL / LI
 *----------------------------------------------------------------------------*/
ul.fancytree-container {
  font-family: tahoma, arial, helvetica;
  font-size: 10pt;
  white-space: nowrap;
  padding: 3px;
  margin: 0;
  background-color: white;
  border: 1px dotted gray;
  min-height: 0%;
  position: relative;
}
ul.fancytree-container ul {
  padding: 0 0 0 16px;
  margin: 0;
}
ul.fancytree-container ul > li:before {
  content: none;
}
ul.fancytree-container li {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  -moz-background-clip: border;
  -moz-background-inline-policy: continuous;
  -moz-background-origin: padding;
  background-attachment: scroll;
  background-color: transparent;
  background-position: 0px 0px;
  background-repeat: repeat-y;
  background-image: none;
  margin: 0;
}
ul.fancytree-container li.fancytree-lastsib {
  background-image: none;
}
.ui-fancytree-disabled ul.fancytree-container {
  opacity: 0.5;
  background-color: silver;
}
ul.fancytree-connectors.fancytree-container li {
  /* background-image: url("../skin-win8/vline.gif"); */
  background-position: 0 0;
}
ul.fancytree-container li.fancytree-lastsib,
ul.fancytree-no-connector > li {
  background-image: none;
}
li.fancytree-animating {
  position: relative;
}
/*------------------------------------------------------------------------------
 * Common icon definitions
 *----------------------------------------------------------------------------*/
span.fancytree-empty,
span.fancytree-vline,
span.fancytree-expander,
span.fancytree-icon,
span.fancytree-checkbox,
span.fancytree-drag-helper-img,
#fancytree-drop-marker {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: left;
  /* background-image: url("../skin-win8/icons.gif"); */
  background-position: 0px 0px;
}
span.fancytree-icon,
span.fancytree-checkbox,
span.fancytree-expander,
span.fancytree-custom-icon {
  margin-top: 2px;
}
/* Used by icon option: */
span.fancytree-custom-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 3px;
  background-position: 0px 0px;
}
/* Used by 'icon' node option: */
img.fancytree-icon {
  width: 16px;
  height: 16px;
  margin-left: 3px;
  margin-top: 2px;
  vertical-align: top;
  border-style: none;
}
/*------------------------------------------------------------------------------
 * Expander icon
 *
 * Note: IE6 doesn't correctly evaluate multiples class names,
 *		 so we create combined class names that can be used in the CSS.
 *
 * Prefix: fancytree-exp-
 * 1st character: 'e': expanded, 'c': collapsed, 'n': no children
 * 2nd character (optional): 'd': lazy (Delayed)
 * 3rd character (optional): 'l': Last sibling
 *----------------------------------------------------------------------------*/
span.fancytree-expander {
  cursor: pointer;
}
.fancytree-exp-n span.fancytree-expander,
.fancytree-exp-nl span.fancytree-expander {
  background-image: none;
  cursor: default;
}
.fancytree-connectors .fancytree-exp-n span.fancytree-expander,
.fancytree-connectors .fancytree-exp-nl span.fancytree-expander {
  /* background-image: url("../skin-win8/icons.gif"); */
  margin-top: 0;
}
.fancytree-connectors .fancytree-exp-n span.fancytree-expander,
.fancytree-connectors .fancytree-exp-n span.fancytree-expander:hover {
  background-position: 0px -64px;
}
.fancytree-connectors .fancytree-exp-nl span.fancytree-expander,
.fancytree-connectors .fancytree-exp-nl span.fancytree-expander:hover {
  background-position: -16px -64px;
}
.fancytree-exp-c span.fancytree-expander {
  background-position: 0px -80px;
}
.fancytree-exp-c span.fancytree-expander:hover {
  background-position: -16px -80px;
}
.fancytree-exp-cl span.fancytree-expander {
  background-position: 0px -96px;
}
.fancytree-exp-cl span.fancytree-expander:hover {
  background-position: -16px -96px;
}
.fancytree-exp-cd span.fancytree-expander {
  background-position: -64px -80px;
}
.fancytree-exp-cd span.fancytree-expander:hover {
  background-position: -80px -80px;
}
.fancytree-exp-cdl span.fancytree-expander {
  background-position: -64px -96px;
}
.fancytree-exp-cdl span.fancytree-expander:hover {
  background-position: -80px -96px;
}
.fancytree-exp-e span.fancytree-expander,
.fancytree-exp-ed span.fancytree-expander {
  background-position: -32px -80px;
}
.fancytree-exp-e span.fancytree-expander:hover,
.fancytree-exp-ed span.fancytree-expander:hover {
  background-position: -48px -80px;
}
.fancytree-exp-el span.fancytree-expander,
.fancytree-exp-edl span.fancytree-expander {
  background-position: -32px -96px;
}
.fancytree-exp-el span.fancytree-expander:hover,
.fancytree-exp-edl span.fancytree-expander:hover {
  background-position: -48px -96px;
}
/* Fade out expanders, when container is not hovered or active */
.fancytree-fade-expander span.fancytree-expander {
  transition: opacity 1.5s;
  opacity: 0;
}
.fancytree-fade-expander:hover span.fancytree-expander,
.fancytree-fade-expander.fancytree-treefocus span.fancytree-expander,
.fancytree-fade-expander .fancytree-treefocus span.fancytree-expander,
.fancytree-fade-expander [class*='fancytree-statusnode-'] span.fancytree-expander {
  transition: opacity 0.6s;
  opacity: 1;
}
/*------------------------------------------------------------------------------
 * Checkbox icon
 *----------------------------------------------------------------------------*/
span.fancytree-checkbox {
  margin-left: 3px;
  background-position: 0px -32px;
}
span.fancytree-checkbox:hover {
  background-position: -16px -32px;
}
span.fancytree-checkbox.fancytree-radio {
  background-position: 0px -48px;
}
span.fancytree-checkbox.fancytree-radio:hover {
  background-position: -16px -48px;
}
.fancytree-partsel span.fancytree-checkbox {
  background-position: -64px -32px;
}
.fancytree-partsel span.fancytree-checkbox:hover {
  background-position: -80px -32px;
}
.fancytree-partsel span.fancytree-checkbox.fancytree-radio {
  background-position: -64px -48px;
}
.fancytree-partsel span.fancytree-checkbox.fancytree-radio:hover {
  background-position: -80px -48px;
}
.fancytree-selected span.fancytree-checkbox {
  background-position: -32px -32px;
}
.fancytree-selected span.fancytree-checkbox:hover {
  background-position: -48px -32px;
}
.fancytree-selected span.fancytree-checkbox.fancytree-radio {
  background-position: -32px -48px;
}
.fancytree-selected span.fancytree-checkbox.fancytree-radio:hover {
  background-position: -48px -48px;
}
.fancytree-unselectable span.fancytree-checkbox {
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.fancytree-unselectable span.fancytree-checkbox:hover {
  background-position: 0px -32px;
}
.fancytree-unselectable.fancytree-partsel span.fancytree-checkbox:hover {
  background-position: -64px -32px;
}
.fancytree-unselectable.fancytree-selected span.fancytree-checkbox:hover {
  background-position: -32px -32px;
}
/*------------------------------------------------------------------------------
 * Node type icon
 * Note: IE6 doesn't correctly evaluate multiples class names,
 *		 so we create combined class names that can be used in the CSS.
 *
 * Prefix: fancytree-ico-
 * 1st character: 'e': expanded, 'c': collapsed
 * 2nd character (optional): 'f': folder
 *----------------------------------------------------------------------------*/
span.fancytree-icon {
  margin-left: 3px;
  background-position: 0px 0px;
}
/* Documents */
.fancytree-ico-c span.fancytree-icon:hover {
  background-position: -16px 0px;
}
.fancytree-has-children.fancytree-ico-c span.fancytree-icon {
  background-position: -32px 0px;
}
.fancytree-has-children.fancytree-ico-c span.fancytree-icon:hover {
  background-position: -48px 0px;
}
.fancytree-ico-e span.fancytree-icon {
  background-position: -64px 0px;
}
.fancytree-ico-e span.fancytree-icon:hover {
  background-position: -80px 0px;
}
/* Folders */
.fancytree-ico-cf span.fancytree-icon {
  background-position: 0px -16px;
}
.fancytree-ico-cf span.fancytree-icon:hover {
  background-position: -16px -16px;
}
.fancytree-has-children.fancytree-ico-cf span.fancytree-icon {
  background-position: -32px -16px;
}
.fancytree-has-children.fancytree-ico-cf span.fancytree-icon:hover {
  background-position: -48px -16px;
}
.fancytree-ico-ef span.fancytree-icon {
  background-position: -64px -16px;
}
.fancytree-ico-ef span.fancytree-icon:hover {
  background-position: -80px -16px;
}
.fancytree-loading span.fancytree-expander,
.fancytree-loading span.fancytree-expander:hover,
.fancytree-statusnode-loading span.fancytree-icon,
.fancytree-statusnode-loading span.fancytree-icon:hover {
  /* background-image: url("../skin-win8/loading.gif"); */
  background-position: 0px 0px;
}
/* Status node icons */
.fancytree-statusnode-error span.fancytree-icon,
.fancytree-statusnode-error span.fancytree-icon:hover {
  background-position: 0px -112px;
}
/*------------------------------------------------------------------------------
 * Node titles and highlighting
 *----------------------------------------------------------------------------*/
span.fancytree-node {
  /* See #117 */
  display: inherit;
  width: 100%;
  margin-top: 0px;
  min-height: 20px;
}
span.fancytree-title {
  color: black;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  min-height: 20px;
  padding: 0 3px 0 3px;
  margin: 0px 0 0 3px;
  border: 1px solid transparent;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
}
span.fancytree-node.fancytree-error span.fancytree-title {
  color: red;
}
/*------------------------------------------------------------------------------
 * Drag'n'drop support
 *----------------------------------------------------------------------------*/
div.fancytree-drag-helper span.fancytree-childcounter,
div.fancytree-drag-helper span.fancytree-dnd-modifier {
  display: inline-block;
  color: #fff;
  background: #337ab7;
  border: 1px solid gray;
  min-width: 10px;
  height: 10px;
  line-height: 1;
  vertical-align: baseline;
  border-radius: 10px;
  padding: 2px;
  text-align: center;
  font-size: 9px;
}
div.fancytree-drag-helper span.fancytree-childcounter {
  position: absolute;
  top: -6px;
  right: -6px;
}
div.fancytree-drag-helper span.fancytree-dnd-modifier {
  background: #5cb85c;
  border: none;
  font-weight: bolder;
}
div.fancytree-drag-helper.fancytree-drop-accept span.fancytree-drag-helper-img {
  background-position: -32px -112px;
}
div.fancytree-drag-helper.fancytree-drop-reject span.fancytree-drag-helper-img {
  background-position: -16px -112px;
}
/*** Drop marker icon *********************************************************/
#fancytree-drop-marker {
  width: 32px;
  position: absolute;
  background-position: 0px -128px;
  margin: 0;
}
#fancytree-drop-marker.fancytree-drop-after,
#fancytree-drop-marker.fancytree-drop-before {
  width: 64px;
  background-position: 0px -144px;
}
#fancytree-drop-marker.fancytree-drop-copy {
  background-position: -64px -128px;
}
#fancytree-drop-marker.fancytree-drop-move {
  background-position: -32px -128px;
}
/*** Source node while dragging ***********************************************/
span.fancytree-drag-source.fancytree-drag-remove {
  opacity: 0.15;
}
/*** Target node while dragging cursor is over it *****************************/
/*------------------------------------------------------------------------------
 * 'rtl' option
 *----------------------------------------------------------------------------*/
.fancytree-container.fancytree-rtl .fancytree-title {
  /*unicode-bidi: bidi-override;*/
  /* optional: reverse title letters */
}
.fancytree-container.fancytree-rtl span.fancytree-connector,
.fancytree-container.fancytree-rtl span.fancytree-expander,
.fancytree-container.fancytree-rtl span.fancytree-icon,
.fancytree-container.fancytree-rtl span.fancytree-drag-helper-img,
.fancytree-container.fancytree-rtl #fancytree-drop-marker {
  /* background-image: url("../skin-win8/icons-rtl.gif"); */
}
.fancytree-container.fancytree-rtl .fancytree-exp-n span.fancytree-expander,
.fancytree-container.fancytree-rtl .fancytree-exp-nl span.fancytree-expander {
  background-image: none;
}
.fancytree-container.fancytree-rtl.fancytree-connectors .fancytree-exp-n span.fancytree-expander,
.fancytree-container.fancytree-rtl.fancytree-connectors .fancytree-exp-nl span.fancytree-expander {
  /* background-image: url("../skin-win8/icons-rtl.gif"); */
}
ul.fancytree-container.fancytree-rtl ul {
  padding: 0 16px 0 0;
}
ul.fancytree-container.fancytree-rtl.fancytree-connectors li {
  background-position: right 0;
  /* background-image: url("../skin-win8/vline-rtl.gif"); */
}
ul.fancytree-container.fancytree-rtl li.fancytree-lastsib,
ul.fancytree-container.fancytree-rtl.fancytree-no-connector > li {
  background-image: none;
}
/*------------------------------------------------------------------------------
 * 'table' extension
 *----------------------------------------------------------------------------*/
table.fancytree-ext-table {
  border-collapse: collapse;
}
table.fancytree-ext-table span.fancytree-node {
  display: inline-block;
  box-sizing: border-box;
}
/*------------------------------------------------------------------------------
 * 'columnview' extension
 *----------------------------------------------------------------------------*/
table.fancytree-ext-columnview tbody tr td {
  position: relative;
  border: 1px solid gray;
  vertical-align: top;
  overflow: auto;
}
table.fancytree-ext-columnview tbody tr td > ul {
  padding: 0;
}
table.fancytree-ext-columnview tbody tr td > ul li {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  -moz-background-clip: border;
  -moz-background-inline-policy: continuous;
  -moz-background-origin: padding;
  background-attachment: scroll;
  background-color: transparent;
  background-position: 0px 0px;
  background-repeat: repeat-y;
  background-image: none;
  /* no v-lines */
  margin: 0;
}
table.fancytree-ext-columnview span.fancytree-node {
  position: relative;
  /* allow positioning of embedded spans */
  display: inline-block;
}
table.fancytree-ext-columnview span.fancytree-node.fancytree-expanded {
  background-color: #CBE8F6;
}
table.fancytree-ext-columnview .fancytree-has-children span.fancytree-cv-right {
  position: absolute;
  right: 3px;
  background-position: 0px -80px;
}
table.fancytree-ext-columnview .fancytree-has-children span.fancytree-cv-right:hover {
  background-position: -16px -80px;
}
/*------------------------------------------------------------------------------
 * 'filter' extension
 *----------------------------------------------------------------------------*/
.fancytree-ext-filter-dimm span.fancytree-node span.fancytree-title {
  color: silver;
  font-weight: lighter;
}
.fancytree-ext-filter-dimm tr.fancytree-submatch span.fancytree-title,
.fancytree-ext-filter-dimm span.fancytree-node.fancytree-submatch span.fancytree-title {
  color: black;
  font-weight: normal;
}
.fancytree-ext-filter-dimm tr.fancytree-match span.fancytree-title,
.fancytree-ext-filter-dimm span.fancytree-node.fancytree-match span.fancytree-title {
  color: black;
  font-weight: bold;
}
.fancytree-ext-filter-hide tr.fancytree-hide,
.fancytree-ext-filter-hide span.fancytree-node.fancytree-hide {
  display: none;
}
.fancytree-ext-filter-hide tr.fancytree-submatch span.fancytree-title,
.fancytree-ext-filter-hide span.fancytree-node.fancytree-submatch span.fancytree-title {
  color: silver;
  font-weight: lighter;
}
.fancytree-ext-filter-hide tr.fancytree-match span.fancytree-title,
.fancytree-ext-filter-hide span.fancytree-node.fancytree-match span.fancytree-title {
  color: black;
  font-weight: normal;
}
/* Hide expanders if all child nodes are hidden by filter */
.fancytree-ext-filter-hide-expanders tr.fancytree-match span.fancytree-expander,
.fancytree-ext-filter-hide-expanders span.fancytree-node.fancytree-match span.fancytree-expander {
  visibility: hidden;
}
.fancytree-ext-filter-hide-expanders tr.fancytree-submatch span.fancytree-expander,
.fancytree-ext-filter-hide-expanders span.fancytree-node.fancytree-submatch span.fancytree-expander {
  visibility: visible;
}
.fancytree-ext-childcounter span.fancytree-icon,
.fancytree-ext-filter span.fancytree-icon {
  position: relative;
}
.fancytree-ext-childcounter span.fancytree-childcounter,
.fancytree-ext-filter span.fancytree-childcounter {
  color: #fff;
  background: #777;
  border: 1px solid gray;
  position: absolute;
  top: -6px;
  right: -6px;
  min-width: 10px;
  height: 10px;
  line-height: 1;
  vertical-align: baseline;
  border-radius: 10px;
  padding: 2px;
  text-align: center;
  font-size: 9px;
}
/*------------------------------------------------------------------------------
 * 'wide' extension
 *----------------------------------------------------------------------------*/
ul.fancytree-ext-wide {
  position: relative;
  min-width: 100%;
  z-index: 2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
ul.fancytree-ext-wide span.fancytree-node > span {
  position: relative;
  z-index: 2;
}
ul.fancytree-ext-wide span.fancytree-node span.fancytree-title {
  position: absolute;
  z-index: 1;
  left: 0px;
  min-width: 100%;
  margin-left: 0;
  margin-right: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/*------------------------------------------------------------------------------
 * 'fixed' extension
 *----------------------------------------------------------------------------*/
.fancytree-ext-fixed-wrapper .fancytree-ext-fixed-hidden {
  display: none;
}
.fancytree-ext-fixed-wrapper div.fancytree-ext-fixed-scroll-border-bottom {
  border-bottom: 3px solid rgba(0, 0, 0, 0.75);
}
.fancytree-ext-fixed-wrapper div.fancytree-ext-fixed-scroll-border-right {
  border-right: 3px solid rgba(0, 0, 0, 0.75);
}
.fancytree-ext-fixed-wrapper div.fancytree-ext-fixed-wrapper-tl {
  position: absolute;
  overflow: hidden;
  z-index: 3;
  top: 0px;
  left: 0px;
}
.fancytree-ext-fixed-wrapper div.fancytree-ext-fixed-wrapper-tr {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  top: 0px;
}
.fancytree-ext-fixed-wrapper div.fancytree-ext-fixed-wrapper-bl {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  left: 0px;
}
.fancytree-ext-fixed-wrapper div.fancytree-ext-fixed-wrapper-br {
  position: absolute;
  overflow: scroll;
  z-index: 1;
}
/*******************************************************************************
 * Styles specific to this skin.
 *
 * This section is automatically generated from the `ui-fancytree.less` template.
 ******************************************************************************/
/*******************************************************************************
 * Node titles
 */
.fancytree-plain span.fancytree-title {
  border: 1px solid transparent;
}
.fancytree-plain.fancytree-container.fancytree-treefocus span.fancytree-focused span.fancytree-title {
  border-color: #3399ff;
}
.fancytree-plain span.fancytree-active span.fancytree-title,
.fancytree-plain span.fancytree-selected span.fancytree-title {
  background-color: #f7f7f7;
  border-color: #dedede;
}
.fancytree-plain span.fancytree-node span.fancytree-selected span.fancytree-title {
  font-style: italic;
}
.fancytree-plain span.fancytree-node:hover span.fancytree-title {
  background-color: #eff9fe;
  border-color: #70c0e7;
}
.fancytree-plain.fancytree-container.fancytree-treefocus span.fancytree-active span.fancytree-title,
.fancytree-plain.fancytree-container.fancytree-treefocus span.fancytree-selected span.fancytree-title {
  background-color: #cbe8f6;
  border-color: #26a0da;
}
/*******************************************************************************
 * 'table' extension
 */
table.fancytree-ext-table tbody tr td {
  border: 1px solid #EDEDED;
}
table.fancytree-ext-table tbody span.fancytree-node,
table.fancytree-ext-table tbody span.fancytree-node:hover {
  border: none;
  background: none;
}
table.fancytree-ext-table tbody tr:hover {
  background-color: #E5F3FB;
  outline: 1px solid #70C0E7;
}
table.fancytree-ext-table tbody tr.fancytree-focused span.fancytree-title {
  outline: 1px dotted black;
}
table.fancytree-ext-table tbody tr.fancytree-active:hover,
table.fancytree-ext-table tbody tr.fancytree-selected:hover {
  background-color: #CBE8F6;
  outline: 1px solid #26A0DA;
}
table.fancytree-ext-table tbody tr.fancytree-active {
  background-color: #F7F7F7;
  outline: 1px solid #DEDEDE;
}
table.fancytree-ext-table tbody tr.fancytree-selected {
  background-color: #F7F7F7;
}
table.fancytree-ext-table.fancytree-treefocus tbody tr.fancytree-active {
  background-color: #CBE8F6;
  outline: 1px solid #26A0DA;
}
table.fancytree-ext-table.fancytree-treefocus tbody tr.fancytree-selected {
  background-color: #CBE8F6;
}